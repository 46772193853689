.background {
    min-height: calc(100vh - 100px);
}

.grid-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 1rem;
}

.grid-item {
    grid-column: span 4 / span 4;
}

/* Grille pour les écrans sm et plus grands */
@media (min-width: 640px) {
    .grid-container {
        grid-template-columns: repeat(8, 1fr);
        gap: 1.5rem;
    }
    .grid-item {
        grid-column: span 8 / span 8;
    }
}

/* Padding horizontal pour les écrans md et plus grands */
@media (min-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(12, 1fr);
        gap: 1.5rem;
    }
    .grid-item {
        grid-column: span 12 / span 12;
    }
}
