/* Tailwind CSS Config */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google Fonts imports */
@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&family=Roboto+Slab:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&family=Caveat:wght@400..700&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

/* Fonts imports */
/* Webfont: Luciole-Regular */
@font-face {
    font-family: "Luciole";
    src:
        url("/src/assets/fonts/Luciole/Luciole-Regular.woff") format("woff"),
        url("/src/assets/fonts/Luciole/Luciole-Regular.woff2") format("woff2"),
        url("/src/assets/fonts/Luciole/Luciole-Regular.ttf") format("truetype"),
        /* Safari, Android, iOS */
            url("/src/assets/fonts/Luciole/Luciole-Regular.svg#Luciole-Regular")
            format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
