.header-border {
    position: relative;
}

.header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 2px -2px gray;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.header.hidden {
    transform: translateY(-100%);
}

.header.visible {
    transform: translateY(0);
}

.header.fixed {
    position: fixed; /* Quand on scroll vers le haut, il devient fixe */
    top: 0;
    left: 0;
}

.reduced-size {
    height: 80px; /* Optionnel : animation de la transition */
}

.header.no-transition {
    transition: none !important; /* Désactive la transition */
}
